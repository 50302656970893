<template>
    <div class="k-card-3 k-gap-l">
        <div class="k-flex-row">
            <div class="title">{{ config.name }}</div>
            <div class="flex-grow-1" />
            <b-icon-info-circle :id="`about-${config.id}`" variant="kalkin-2" />
        </div>

        <b-popover
            :target="`about-${config.id}`"
            triggers="hover"
            placement="bottomleft"
            variant="secondary"
            class="mr-1"
        >
            <div>
                {{ application.name }}
                <small>{{ build.version }}</small>
            </div>
            <div>
                {{ application.template }}
                <small>{{ templateConfig.version }}</small>
            </div>
        </b-popover>

        <div class="k-flex-row k-flex-fill flex-grow-1">
            <div class="text-center" v-if="!config.Installations.length">
                <em>Aucune installation</em>
            </div>
            <div class="align-self-start" v-else>
                Installations :
                <div
                    class="install-item"
                    v-for="install in config.Installations.slice(0, 3)"
                    :key="install.id"
                >
                    {{ install.name }}
                </div>
                <div v-if="config.Installations.length > 3">
                    <em class="ml-3">
                        ... et {{ config.Installations.length - 3 }} en plus
                    </em>
                </div>
            </div>
            <div class="k-flex-center">
                <img
                    class="icon-template"
                    :src="templateIcon"
                    :alt="build.Application.template"
                />
            </div>
        </div>

        <div class="k-flex-center">
            <b-button
                class="px-4"
                variant="kalkin-2"
                @click="goConfigDetails(config.id)"
            >
                Choisir <b-icon-arrow-right-circle />
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        config: Object,
    },

    computed: {
        build() {
            return this.config.ApplicationBuild || {};
        },

        application() {
            return this.build.Application || {};
        },

        templateConfig() {
            return this.build.TemplateConfig || {};
        },

        templateIcon() {
            const template = this.config.ApplicationBuild.Application.template.toLowerCase();

            try {
                return require(`@/assets/img/template/${template}.svg`);
            } catch (error) {
                return require(`@/assets/img/template/unknown.png`);
            }
        },
    },

    methods: {
        goConfigDetails(id) {
            this.$router.push({
                name: "Config",
                params: {
                    projectId: this.$route.params.projectId,
                    configId: id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.icon-template {
    max-width: 100%;
    padding: 0 30px;
}

.install-item {
    padding: 3px 10px;
    margin: 5px 0;
    border-radius: 3px;
    background: $theme-bg-3;
    color: $theme-fg-3;
}
</style>
