<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1><small>Projet</small> {{ project.name }}</h1>
            <b-button variant="kalkin-1" @click="goProjects">
                <b-icon-arrow-counterclockwise /> Changer de projet
            </b-button>
        </div>

        <div class="k-bloc-m m-2">{{ project.description }}</div>

        <div class="k-flex-row">
            <h3>Mes configurations</h3>
            <div class="k-flex-line" />
        </div>

        <GridCardBasic
            :data="configs"
            empty="Aucune configuration"
            minCardSize="400px"
            gap="20px"
        >
            <template v-slot="{ data }">
                <CardApplicationConfigMember :config="data" />
            </template>
        </GridCardBasic>
    </div>
</template>

<script>
import GridCardBasic from "@/components/ui/GridCardBasic";
import CardApplicationConfigMember from "@/components/model/applicationconfig/CardApplicationConfigMember";

export default {
    components: {
        GridCardBasic,
        CardApplicationConfigMember,
    },

    data: function() {
        return {
            project: {},
            configs: [],
        };
    },

    methods: {
        async fetchProject() {
            const id = this.$route.params.projectId;
            this.project = await this.$store.dispatch("project/getProject", {
                projectId: id,
            });
        },

        async fetchConfigs() {
            const projectId = this.$route.params.projectId;
            this.configs = await this.$store.dispatch(
                "application/getApplicationConfigs",
                { projectId },
            );
        },

        goProjects() {
            this.$router.push({
                name: "Projects",
            });
        },
    },

    async beforeMount() {
        await this.fetchProject();
        await this.fetchConfigs();
    },
};
</script>

<style></style>
